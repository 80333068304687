.bubble {
  position: relative;
  font-family: sans-serif;
  background: lightgray;
  border-radius: 40px;
  padding: 24px;
  text-align: center;
  color: #000;
  margin: 0 auto;
}

.bubble-bottom-right:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid lightgray;
  border-right: 12px solid transparent;
  border-top: 12px solid lightgray;
  border-bottom: 20px solid transparent;
  right: 32px;
  bottom: -24px;
}
